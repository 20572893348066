import React, { useState } from 'react'
import clsx from 'clsx'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarDays,
  faQuoteRight,
  faQuoteLeft
} from '@fortawesome/free-solid-svg-icons'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import PaymentModal from '../../components/PaymentModal'
import BackToTopButton from '../../components/BackToTopButton'
import Link from '../../components/LinkWrapper'
// import useAuth from '../../hooks/useAuth'
import i18n from '../../locales/he.yaml'
import { prettyPrice } from '../../utils'

const course = i18n.courses.nonAcademyCourses.find(
  (course) => course.name === 'Live Show'
)

const LiveShowQuery = graphql`
  query LiveShowQuery {
    hook: file(
      relativePath: { eq: "ספינת-פיראטים-עם-קפטן-הוק-מעוצבת-בבצק-סוכר.JPG" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    speedy: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-שני-בניה-ועוגת-ספידי-גונזלס-בתלת-מימד-מפוסלת-בבצק-סוכר.JPG"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    giraffe: file(
      relativePath: { eq: "ג׳ירפה-ענקית-בתלת-מימד-מפוסלת-בבצק-סוכר.JPG" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    survival: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-הישרדות-ענקית-מרחפת-בגובה-מטר-מפוסלת-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
  }
`

const LiveShowTemplate = ({ pathname, data }) => {
  // const { isInAcademy } = useAuth()
  const [isPaymentForm, setIsPaymentForm] = useState(false)
  const { speedy, hook, giraffe, survival } = data

  const BULLETS_IMAGE = [speedy, giraffe, hook]

  let paymentFullPrice = course.pricing.paymentPrice
  let paymentPrice = course.pricing.paymentPrice

  let launchDiscountRate = 0
  if (course.isInLaunch) {
    launchDiscountRate = 10
  }
  paymentPrice = Math.ceil(paymentPrice * ((100 - launchDiscountRate) / 100))

  const fullPricePaymentsText = `${course.pricing.numOfPayments} ${i18n.courses.payments} * ${paymentFullPrice} ${i18n.courses.currency}`
  const fullPriceText = `(${i18n.courses.total} ${prettyPrice(
    paymentFullPrice * course.pricing.numOfPayments
  )} ${i18n.courses.currency})`

  const pricePaymentsText = `${course.pricing.numOfPayments} ${i18n.courses.payments} * ${paymentPrice} ${i18n.courses.currency}`
  const priceText = `(${i18n.courses.total} ${prettyPrice(
    paymentPrice * course.pricing.numOfPayments
  )} ${i18n.courses.currency})`

  const Pricing = () => (
    <div className='hero has-background-nude-light'>
      <div className='hero-body'>
        <div className='container has-text-centered content'>
          <h3>{course.pricing.title}</h3>
          <div className='has-text-centered is-family-secondary block'>
            <span
              className='is-block is-size-5 has-text-weight-bold'
              style={{
                textDecoration:
                  launchDiscountRate > 0 ? 'line-through' : undefined
              }}
            >
              {fullPricePaymentsText}
            </span>
            <span
              className='is-block'
              style={{
                textDecoration:
                  launchDiscountRate > 0 ? 'line-through' : undefined
              }}
            >
              {fullPriceText}
            </span>
          </div>
          {launchDiscountRate > 0 && (
            <p className='is-italic'>
              {course.pricing.launchDiscountPrefix}{' '}
              <span className='has-text-weight-bold is-family-secondary'>
                % {launchDiscountRate}
              </span>
            </p>
          )}
          {launchDiscountRate > 0 && (
            <>
              <p>{course.pricing.launchInvestmentTitle}</p>
              <div className='has-text-centered is-family-secondary block'>
                <span className='is-block is-size-5 has-text-weight-bold'>
                  {pricePaymentsText}
                </span>
                <span className='is-block'>{priceText}</span>
              </div>
            </>
          )}
          <button
            className='button is-nude is-medium'
            onClick={() => setIsPaymentForm(true)}
          >
            {course.pricing.callToAction}
          </button>
        </div>
      </div>
    </div>
  )

  return (
    <>
      {isPaymentForm && (
        <PaymentModal
          onClose={() => setIsPaymentForm(false)}
          welcomeImage={giraffe}
          successLabel={course.successLabel}
          course={course}
        />
      )}
      <BackToTopButton selectorId='live-show' />
      <div className='hero has-navbar-fixed-top'>
        <div className='hero-body is-padding-top-6 is-padding-bottom-6'>
          <div className='container'>
            <nav className='breadcrumb' aria-label='breadcrumbs'>
              <ul>
                <li>
                  <Link to={'/academy/'}>{i18n.header.academy}</Link>
                </li>
                <li className='is-active'>
                  <Link to={pathname} aria-current='page'>
                    {course.name}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <div className='columns is-centered'>
              <div className='column'>
                <h2 className='is-size-4 has-text-nude'>{course.subtitle}</h2>
                <h1 className='is-size-4'>{course.title}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {course.bullets.map((bullet, index) => (
        <div
          id={index === 1 ? 'live-show' : undefined}
          key={index}
          className={clsx('hero', {})}
        >
          <div className='hero-body'>
            <div className='container'>
              <div
                className={clsx(
                  'columns is-mobile is-multiline is-centered is-vcentered',
                  {
                    'direction-row-reverse': index % 2 === 1
                  }
                )}
              >
                <div
                  className={clsx(
                    'column is-full-real-mobile is-10-mobile is-7-tablet is-6-desktop is-5-widescreen',
                    {
                      'is-offset-1-widescreen': index % 2 === 1
                    }
                  )}
                >
                  <GatsbyImage
                    image={getImage(BULLETS_IMAGE[index])}
                    title={bullet.image.title}
                    alt={bullet.image.alt}
                    className='live-image'
                    style={{
                      maxWidth: getImage(BULLETS_IMAGE[index]).width,
                      margin: '0 auto' // used to center the image
                    }}
                    loading='eager'
                  />
                  <div className='is-flex justify-center content'>
                    <p className='has-text-centered has-text-gold-black'>
                      {bullet.image.title}
                    </p>
                  </div>
                </div>
                <div
                  className={clsx(
                    'column is-full-mobile is-10-tablet is-6-desktop',
                    {
                      'is-offset-1-widescreen': index % 2 === 0
                    }
                  )}
                >
                  <div className='content'>
                    <h3>{bullet.title}</h3>
                    <p>{bullet.description}</p>
                    {bullet.cta && (
                      <p className='is-margin-top-4'>
                        <Link
                          to={bullet.cta.link}
                          className='button is-link-ka is-text pl-0'
                        >
                          {bullet.cta.text}
                        </Link>
                      </p>
                    )}
                    <strong>{bullet.question}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <FontAwesomeIcon
              icon={faCalendarDays}
              className='has-text-nude is-margin-bottom-4'
              size='lg'
            />
            <div className='is-margin-bottom-4'>
              <h3>{course.when.title}</h3>
            </div>
            <p>{course.when.description}</p>
            <p className='is-italic'>{course.when.hint}</p>
          </div>
        </div>
      </div>
      <Pricing />
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content'>
            <div className='has-text-centered is-margin-bottom-6'>
              <h3>{course.testimonials.title}</h3>
            </div>
            {course.testimonials.testimonials.map((testimony, index) => (
              <div key={testimony.title} className='columns is-centered'>
                <div className='column is-6-widescreen is-8-desktop is-10-tablet is-12-mobile'>
                  <div
                    className={clsx(
                      'columns is-flex-direction-column is-mobile is-align-items-center',
                      { 'direction-column-reverse': testimony.isEnglish }
                    )}
                  >
                    <div className='column is-align-self-flex-start'>
                      <FontAwesomeIcon icon={faQuoteRight} size='lg' />
                    </div>
                    <div
                      className='column is-padding-top-0 is-padding-bottom-0'
                      dir={testimony.isEnglish ? 'ltr' : 'rtl'}
                    >
                      <h4 className='has-text-gold-black is-margin-bottom-0'>
                        {testimony.title}
                      </h4>
                    </div>
                    <div className='column is-align-self-flex-end'>
                      <FontAwesomeIcon icon={faQuoteLeft} size='lg' />
                    </div>
                  </div>
                  <div
                    className={clsx('columns is-align-items-start', {
                      'direction-row-reverse': testimony.isEnglish
                    })}
                  >
                    <div className='column is-narrow'>
                      <p className='has-text-centered has-text-weight-bold'>
                        {testimony.name}
                      </p>
                    </div>
                    <div className='column'>
                      <p
                        className='is-margin-bottom-0'
                        dir={testimony.isEnglish ? 'ltr' : 'rtl'}
                      >
                        {testimony.text}
                      </p>
                    </div>
                  </div>
                  {index < course.testimonials.testimonials.length - 1 && (
                    <hr />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns is-mobile is-multiline is-centered'>
              <div className='column content is-margin-bottom-0 is-full-mobile is-10-tablet is-6-desktop'>
                <h3>{course.about.title}</h3>
                <p>{course.about.description}</p>
              </div>
              <div className='column is-4-fullhd is-5-widescreen is-half-tablet is-10-mobile is-offset-2-fullhd is-offset-1-widescreen is-order-3-touch'>
                <GatsbyImage
                  image={getImage(survival)}
                  title={course.about.mainImage.title}
                  alt={course.about.mainImage.alt}
                  className='live-image'
                  style={{
                    maxWidth: getImage(survival).width,
                    margin: '0 auto' // used to center the image
                  }}
                />
                <div className='is-flex justify-center content'>
                  <p className='has-text-centered has-text-gold-black'>
                    {course.about.mainImage.title}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pricing />
    </>
  )
}

const LiveShow = ({ location: { pathname, search } }) => {
  const data = useStaticQuery(LiveShowQuery)
  return (
    <Layout>
      <Seo
        metadata={course.metadata}
        pathname={pathname}
        robots='noindex, nofollow'
        og={{
          image: getSrc(data.giraffe),
          alt: course.mainImage.alt
        }}
        schemaMarkup={{
          breadcrumbs: [
            { name: i18n.header.academy, path: '/academy/' },
            { name: course.name, path: '/academy/live-show/' }
          ]
        }}
      />
      <LiveShowTemplate pathname={pathname} search={search} data={data} />
    </Layout>
  )
}

export default LiveShow
